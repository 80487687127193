import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://109af839a3b94e6b8bd2d4d4463e74aa@o570063.ingest.us.sentry.io/6046640",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});